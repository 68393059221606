import { CWBClient } from "../../client";
import ActorMockData from "../../mock/castlist.json";

export const fetchActorsAPI = (agentId) =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: ActorMockData }))
    : CWBClient.get(`${process.env.REACT_APP_API_PM_BASE_PATH || ''}/OnboardingAgentRoster/${agentId}/GetRosters?withVideo=true`);

export const fetchActorDetailAPI = (clientId) =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: ActorMockData }))
    : CWBClient.get(`${process.env.REACT_APP_API_PM_BASE_PATH || ''}/ActorProfile/${clientId}`);
