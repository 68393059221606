import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { space } from "styled-system";
import ChevronRightIcon from "../assets/images/icons/chevron_right.svg";

export const BreadCrumbsRender = styled.ul(
  {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    listStyle: 'none',

    '& > *:last-child': {
      textDecoration: 'underline'
    }
  },
  space
);

export const BreadCrumb = ({ className, external, to, title }) => (
  <StyledLi className={className}>
    {external ? (
      <ExternalLink href={to}>{title}</ExternalLink>
    ) : (
      <InternalLink to={to}>{title}</InternalLink>
    )}
  </StyledLi>
);

const StyledLi = styled.li`
  display: flex;
  align-items: center;

  &:not(:first-child)::before {
    width: 16px;
    height: 16px;
    margin: ${(p) => p.theme.spacing(0, 0.5)};
    content: url(${ChevronRightIcon});
  }
`;

const linkStyles = css`
  color: #758196;
  font-size: ${(p) => p.theme.typography.fontSize.bodyBold};
  font-weight: ${(p) => p.theme.typography.fontWeight.bodyBold};
  line-height: ${(p) => p.theme.typography.lineHeight.bodyBold};
  text-decoration: none;

  &:hover {
    color: #758196;
  }
`;

const ExternalLink = styled.a`
  ${linkStyles}
`;

const InternalLink = styled(Link)`
  ${linkStyles}
`;
