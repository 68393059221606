export const common = {
  state: {
    loading: 0,
    notification: null,
  },
  reducers: {
    setLoading(state, payload) {
      return {
        ...state,
        loading: payload ? state.loading + 1 : Math.max(state.loading - 1, 0),
      };
    },
    setError(state, payload = undefined) {
      return {
        ...state,
        notification: payload,
      };
    },
    setAPIError(state, error) {
      let message;
      if (error.response) {
        console.log({ data: error.response.data, status: error.response.status, hears: error.response.headers });

        message = error.response.data;
      } else if (error.request) {
        console.log(error.request);

        message = "Server Error";
      } else {
        console.log(error);

        message = error.message;
      }

      return {
        ...state,
        notification: {
          type: "error",
          message,
        },
        loading: Math.max(state.loading - 1, 0),
      };
    },
    errorRedirect(state, error) {
      if (!error || !error.response)
      {
        window.location.href = process.env.REACT_APP_BASE_URL + "/error/500.html"
        return;
      }
      
      if (error.response.status == 403
        || error.response.status == 401) {
          window.location.href = process.env.REACT_APP_BASE_URL + "/error/403.html"
        } else {
          window.location.href = process.env.REACT_APP_BASE_URL + "/error/500.html"
        }
    },
    setAPISuccess(state, message = null) {
      return {
        ...state,
        loading: Math.max(state.loading - 1, 0),
        notification: message
          ? {
            message,
            type: "success",
          }
          : null,
      };
    },
  },
};
