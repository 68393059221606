import { Navigate, Route, Routes } from "react-router-dom";

import CastlistsLayout from "./layout/CastlistsLayout";
import SelectActor from "./pages/SelectActor";
import ActorVideos from "./pages/ActorVideos";
import UpdateVideos from "./pages/UpdateVideos";

import { Redirect } from "./components";

import "bootstrap/dist/css/bootstrap.min.css";
import 'cwb-react/dist/cwb-react.css'
import "./App.css";

import "./i18n";
import { detectBrowserLang, setLanguage } from "./helpers"
import { useEffect } from "react";

export default function App() {

  useEffect(() => {
    setLanguage(detectBrowserLang)
  },[]);
  return (
    <Routes>
      <Route path="" element={<Redirect url={"/home"} />} />
      <Route path="/" element={<CastlistsLayout />}>
        <Route path="/home">
          <Route index element={<SelectActor />} />
        </Route>

        <Route path="videos/:actorId" element={<ActorVideos />} />

        <Route path="update-videos" element={<UpdateVideos />} />
      </Route>

      <Route
        path="/404"
        element={
          <Redirect
            url={
              process.env.REACT_APP_MOCK_ON === "TRUE"
                ? "/home"
                : process.env.REACT_APP_404
            }
          />
        }
      />
      <Route path="*" element={<Navigate to={"/404"} replace />} />
    </Routes>
  );
}
