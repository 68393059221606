import * as api from "../api/CWBAPI/video";
import { moment } from 'cwb-react'

export const videos = {
  state: {
    list: [],
    current: {
      selected: [],
      pitchReelTitle: "",
    },
  },
  reducers: {
    setList(state, list) {
      return {
        ...state,
        list,
      };
    },
    setCurrent(state, current) {
      return {
        ...state,
        current: {
          selected: current.selected || [],
          pitchReelTitle: current.pitchReelTitle || "",
        },
      };
    },
    setPitchReelTitle(state, title) {
      return {
        ...state,
        current: {
          ...state.current,
          pitchReelTitle: title,
        },
      };
    }
  },
  effects: (dispatch) => ({
    async fetchActorVideos(_, state) {
      if (!state.actors.selected.clientId && !state.actors.selected.rosterId) return;

      dispatch.common.setLoading(true);

      try {
        let { data: videos } = (state.actors.selected.rosterId
          ? await api.fetchMediaAPIWithRoster(
            state.actors.selected.clientId,
            state.actors.selected.rosterId,
            {
              videosOnly: true,
            }
          )
          : await api.fetchMediaAPI(state.actors.selected.clientId, {
            videosOnly: true
          })
        )

        videos = videos.map(a => {
          a.isStudio = false;
          return a;
        })
        videos = videos.filter(a => !a.isRequestedVideos);

        if (state.auth.user && state.auth.user.id.userTypeId == 3 && state.auth.user.sellerId == 103)  {
          let { data: studioVideos } = (state.actors.selected.rosterId
            ? await api.fetchStudioMediaAPIWithRoster(
              state.actors.selected.clientId,
              state.actors.selected.rosterId,
              {
                videosOnly: true,
              }
            )
            : await api.fetchStudioMediaAPI(state.actors.selected.clientId, {
              videosOnly: true
            })
          )

          studioVideos  = studioVideos.map(a => {
            a.isStudio = true;
            return a;
          })

          videos = videos.concat(studioVideos);
        }

        videos = videos.sort((a, b) => moment.moment(a.createdOn).toDate() < moment.moment(b.createdOn).toDate() ? 1 : -1);

        dispatch.videos.setList(videos);
        dispatch.common.setAPISuccess();

        return videos;
      } catch (e) {
        dispatch.common.setAPIError(e);
      }
    },

    async fetchPitchReelCard(_, state) {
      if (!state.actors.selected.clientId && !state.actors.selected.rosterId) return;
      try {
        let { data: setupData } = (state.actors.selected.rosterId
          ? await api.fetchPitchReelCardWithRoster(
            state.actors.selected.clientId,
            state.actors.selected.rosterId,
            {
              videosOnly: true,
            }
          )
          : await api.fetchPitchReelCard(state.actors.selected.clientId, {
            videosOnly: true
          })
        )
        dispatch.common.setAPISuccess();
        return setupData;
      } catch (e) {
        dispatch.common.setAPIError(e);
      }
    }
  }),
};
