import styled from "styled-components";

export const Backgrop = styled.div`
  width: 100vw;
  height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  background: ${(p) => p.theme.palette.grey[3]};

  z-index: -1;
`;
