import { useMemo } from "react";
import styled from "styled-components";
import { Typography } from "cwb-react";

import { Box } from "../../components";
import Radio from "../../assets/images/icons/radio.svg";
import CheckedRadio from "../../assets/images/icons/radio_checked.svg";
import Avatar from "../../assets/images/avatar.png";

export const Roster = ({ roster, selected, onSelect, style }) => {
  const RadioIcon = useMemo(
    () => (selected ? CheckedRadio : Radio),
    [selected]
  );

  const photoUrl = useMemo(() => {
    if (roster.photos && roster.photos.length) {
      let photo = roster.photos.find((p) => p.isDefault);

      if (!photo) photo = roster.photos[0];

      if (photo) return photo.url;
    }
    return Avatar;
  }, [roster]);

  const name = useMemo(() => {
    return `${roster.firstName} ${roster.middleName} ${roster.lastName}`.replace(
      /\s\s/g,
      " "
    );
  }, [roster]);

  return (
    <Box
      style={style}
      p="15px"
      border={selected ? "1px solid #0F9BF0" : "1px solid #DEE1E6"}
      borderRadius="4px"
      display="flex"
      alignItems="center"
      onClick={onSelect}
    >
      <Box mr="12px">
        <img src={RadioIcon} alt="" width="14" height="14" />
      </Box>
      <Box
        width="65px"
        height="65px"
        position="relative"
        overflow="hidden"
        mr="16px"
      >
        <Photo src={photoUrl} alt={name} />
      </Box>
      <Box>
        <Box mb="2px">
          <Typography variant="bodyBold">{name}</Typography>
        </Box>
        <Box mb="6px">
          <StyledTypography variant="body">
            {roster.clientPrimaryEmailAddress}
          </StyledTypography>
        </Box>
      </Box>
    </Box>
  );
};

const Photo = styled.img`
  width: 100%;
`;

const StyledTypography = styled(Typography)`
  font-size: 10px !important;
  line-height: 14px !important;
`;
