import { CWBClient } from "../../client";
import mockTokenData from "../../mock/jwt-response.json";

export const jwtBridgeAPI = ({ k }) =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: mockTokenData }))
    : CWBClient.post((process.env.REACT_APP_API_AUTH_ROOT_URL || "") + "/auth/jwt/bridge", {
      k,
      appKey: process.env.REACT_APP_APP_KEY || "PitchReels",
    });

export const jwtSeamlessAPI = () =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: mockTokenData }))
    : CWBClient.post((process.env.REACT_APP_API_AUTH_ROOT_URL || "") + "/auth/jwt/seamless",
      { appKey: process.env.REACT_APP_APP_KEY || "PitchReels" },
      { withCredentials: true }
    );

export const jwtAuthAPI = (k) => (k ? jwtBridgeAPI({ k }) : jwtSeamlessAPI());
