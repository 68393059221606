import styled from "styled-components";

import { CWBLoadingCrest } from "cwb-react";

export default function Loading({ show, varant }) {
  if (!show) return null;

  if (varant) {
    if (varant === "embed") {
      return (
        <CWBLoadingCrest
          customLogoSrc={`${process.env.PUBLIC_URL}/images/icon-cwb-logo-grey.svg`}
        />
      );
    }
  }

  return (
    <LoadingRender>
      <CWBLoadingCrest
        customLogoSrc={`${process.env.PUBLIC_URL}/images/icon-cwb-logo-grey.svg`}
      />
    </LoadingRender>
  );
}

const LoadingRender = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: fixed;
  z-index: 999;

  top: 0;
  background: #fff;
`;
