import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { CWBTheme, Checkbox, Typography, TextInput, getCachedWebSessionKey } from "cwb-react";
import { Loading, Info } from "../../components";
import { useEffect, useMemo, useState } from "react";
import { createPopUpOptions, getMediaPopUpUrl } from "../../helpers";
import styled from "styled-components";

import {
  Button,
  Container,
  PageHeader,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableCellHead,
} from "../../components";
import { TableBody } from "../../components/Table";
import ErrorIcon from "../../assets/images/icons/error.svg";

function ActorVideos({ user, actor, actorClientId, actorRosterId, videos, fetchActorVideos, current, ...props }) {
  const { t } = useTranslation();

  const [selectedVideos, setSelectedVideos] = useState(current.selected);
  const [pitchReelTitle, setTitle] = useState(current.pitchReelTitle);
  const [error, setError] = useState(false);

  const { actorId } = useParams();

  const navigate = useNavigate();

  const title = useMemo(
    () =>
      actor
        ? `${t("Pitch Reel for ")} ${actor.firstName} ${actor.lastName}`
        : "",
    [actor]
  );

  useEffect(() => {
    if (actor) fetchActorVideos();
  }, [actorClientId, actorRosterId]);

  useEffect(() => {
    if (actorId) props.fetchActor(actorId);
  }, [actorId]);

  const isChecked = (video) =>
    !!selectedVideos.find((v) => v.mediaId === video.mediaId);

  const isAllChecked = () => selectedVideos.length == videos.length

  const onSelect = (video) => {
    let find = isChecked(video);

    setSelectedVideos((prev) => {
      if (!find) {
        return [...prev, video];
      }

      return prev.filter((v) => v.mediaId !== video.mediaId);
    });
  };

  const onSelectAll = () => {
    const allSelected = isAllChecked();
    if (allSelected)
      setSelectedVideos([])
    else
      setSelectedVideos(Array.from(videos))
  }

  useEffect(() => {
    document.getElementById("root").scrollIntoView();
  }, [selectedVideos]);

  const onNext = () => {
    if (pitchReelTitle.trim() === "") {
      setError(true);
      return;
    }
    props.setCurrent({
      pitchReelTitle,
      selected: selectedVideos,
    });
    navigate("/update-videos");
  };

  const onChangePitchReelTitle = (event) => {
    if (event.target.value !== "") {
      setError(false);
    }

    setTitle(event.target.value);
  };

  const breadcrumbItems = [
    {
      to: `/videos/${actorId}`,
      title: t("New Pitch Reel"),
    },
  ];

  if (user.id.userTypeId !== 8)
    breadcrumbItems.unshift(
      {
        to: "/home",
        title: t("Pitch Reels"),
      },
    )


  const onLinkClick = (ev, item) => {
    ev.preventDefault();
    
    let targetClipClientId = item.clientId || item.clientRId;
    if (!targetClipClientId)
      targetClipClientId = actor.clientId || actor.clientRId;

    const mediaUrl = getMediaPopUpUrl(targetClipClientId, item.mediaId, user.id.userTypeId)
    const popUpOptions = createPopUpOptions(window);
    window.open(mediaUrl, `${actor.firstName} ${actor.lastName} - ${t('Media')}`, popUpOptions);
  }

  const getUploaderVideoPage = () => {

    if (!actor)
      return "#"

    const webSessionKey = getCachedWebSessionKey();
    const rosterTarget = actor.rosterId || actor.rosterRId;

    if (!rosterTarget || user.id.userTypeId == 8) {
      return process.env.REACT_APP_BASE_URL.toLowerCase().replace("www.", "httpuploadvan.") +
        `/upload_mastercast/mastercast_upload.aspx?c=${actor.clientId || actor.clientRId}&k=${webSessionKey}&a=0`
    }

    const result = `/castingworkbook/clients/vroster.asp?rt=${rosterTarget}&c=98`;
    return process.env.REACT_APP_BASE_URL.toLowerCase() + result;
  }

  return (
    <>
      <PageHeader
        title={() => user.id.userTypeId === 8
          ? (
            <>
              {t("Welcome to Pitch Reels")}
              <TitleInfo innerClassName={'wide-tooltip'} tooltip={t(`Pitch Reels allows you to create a new demo reel in a couple of minutes using your uploaded clips. Try one for fun.`)} />
            </>
          )
          : (<>{title}</>)}
        breadcrumbItems={breadcrumbItems}
      ></PageHeader>
      <ContainerLimitedWidth pt="6px">
        <Box py="16px" display="flex" alignItems="center">
          <Box mr="20px">
            <Typography variant="bodyBold">{t("Pitch Reel Clip Title")}</Typography>
          </Box>
          <TextInput
            error={error}
            value={pitchReelTitle}
            onChange={onChangePitchReelTitle}
          />

          {error && (
            <Typography color="error" variant="captionBold">
              <img
                style={{ marginLeft: "4px", marginBottom: "2px" }}
                src={ErrorIcon}
              />{" "}
              {t("This field is required.")}
            </Typography>
          )}

          <RightBox>
            <GoToVideoLink href={getUploaderVideoPage()}>
              <Button
                type="button"
                variant="secondary"
              >
                {t("Upload New Clip")}
              </Button>
            </GoToVideoLink>

          </RightBox>
        </Box>

        {props.loading ? (
          <Loading varant="embed" show={props.loading} />
        ) : (
          <TableWrapper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCellHead>
                    <Checkbox checked={isAllChecked()}
                      onChange={() => onSelectAll()} />
                  </TableCellHead>

                  <TableCellHead>{t("Video")}</TableCellHead>
                  <TableCellHead>{t("Clip Title")}</TableCellHead>
                  <TableCellHead>{t("Length")}</TableCellHead>
                  {/* <TableCellHead>{t("Caption title")}</TableCellHead> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {videos.map((video) => {
                  return (
                    <TableRow key={video.mediaId}>
                      <TableCell>
                        <Checkbox
                          checked={isChecked(video)}
                          onChange={() => onSelect(video)}
                        />
                      </TableCell>
                      <TableCell>
                        <Render>
                          {video.sources && video.sources.length > 0 ? (
                            <a href="#" onClick={(ev) => onLinkClick(ev, video)}><img
                              src={video.polaroid}
                              height={66}
                              width={120}
                              alt={video.description}
                            /></a>
                          ) : (
                            <Typography>
                              {
                                t("No valid media")
                              }
                            </Typography>
                          )}
                        </Render>
                      </TableCell>
                      <TableCell>{video.description}</TableCell>
                      <TableCell>
                        {(video.lengthFriendly || "").replace(/\.(\d+)/g, "")}
                      </TableCell>
                      {/* <TableCell>
                        <TextInput value={""} readOnly />
                      </TableCell> */}
                    </TableRow>
                  );
                })}
                {!videos || videos.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Box
                        mx="6px"
                        mt="24px"
                        mb="21px"
                        bg={CWBTheme.palette.grey[0]}
                        minHeight="238px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                      >
                        <Box textAlign="center" mb="32px">
                          <Typography variant="h5" component="p">
                            {
                              user.id.userTypeId === 8
                                ? t("You don't have any valid media, please upload any videos before trying to create a Pitch Reel")
                                : t("This actor does not have any media, please upload any videos before trying to create a Pitch Reel")
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableWrapper>
        )}

        <Box display="flex" justifyContent="right" py="20px">
          <Button
            variant="primary"
            type="button"
            onClick={() => onNext()}
            disabled={selectedVideos.length === 0}
          >
            {t("Select")}
          </Button>
        </Box>
      </ContainerLimitedWidth>
    </>
  );
}

const mapState = (state) => ({
  videos: state.videos.list,
  loading: state.common.loading > 0,
  actor: state.actors.selected,
  actorClientId: state.actors.selected && state.actors.selected.clientId,
  actorRosterId: state.actors.selected && (state.actors.selected.rosterId || state.actors.selected.rosterRId),
  user: state.auth.user,
  current: state.videos.current
});

const mapDispatch = (dispatch) => ({
  fetchActorVideos: dispatch.videos.fetchActorVideos,
  fetchActor: dispatch.actors.fetchActor,
  setCurrent: dispatch.videos.setCurrent,
});

export default connect(mapState, mapDispatch)(ActorVideos);

const RightBox = styled.div`
flex: 1 1 0;
display: flex;
align-items: end;
flex-direction: column;
`
const GoToVideoLink = styled.a`
text-decoration: none;
}
`

const ContainerLimitedWidth = styled(Container)`
  max-width:1600px
`
const TitleInfo = styled(Info)`
  margin-left: 10px;
  font-size: 16px;
`

const Render = styled.div`
  margin: 5px;
  position: relative;
  display: inline-flex;

  align-items: center;
  justify-content: center;

  width: 120px;
  min-height: 67px;
  img {
    vertical-align: middle;
    object-fit: cover;
  }
`;

const TableWrapper = styled.div`
  max-height: 60vh;
  overflow-y: auto;
  position: relative;
  table {
  
  }
`;
