import { Typography } from 'cwb-react';
import ErrorIcon from "../assets/images/icons/error.svg";
import { Box } from "../components";

export const FormControlErrorMessage = ({ message }) => (
  <Box display="flex" alignItems="center" mt="6px">
    <Box mr="4px" display="flex">
      <img src={ErrorIcon} alt="" />
    </Box>
    <Typography color="error" variant="captionBold">
      {message}
    </Typography>
  </Box>
);
