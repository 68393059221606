import { connect } from "react-redux";
import { PageTitle, Container, Box } from "../components";
import { BreadCrumbsRender, BreadCrumb } from "./BreadCrumb";
import { space } from "styled-system";
import styled from "styled-components";

const PageHeader = ({
  title,
  breadcrumbItems = [],
  children,
  hideDefaultBreadcrumb,
  user,
}) => (
  <PageHeaderRender>
    <Container display="flex" justifyContent="space-between">
      <Box>
        <BreadCrumbWrapper>
          <BreadCrumbsRender marginBottom={12}>
            {!hideDefaultBreadcrumb && (
              <BreadCrumb className="blank"
                external
                to='#'
                title={(user.firstName + " " + user.lastName)}
              />
            )}
            {breadcrumbItems.map((item) => (
              <BreadCrumb key={item.to} {...item} />
            ))}
          </BreadCrumbsRender>
          <PageTitle>{(typeof (title) === "function" ? title() : title)}</PageTitle>
        </BreadCrumbWrapper>
      </Box>
      <Box display="flex" flexDirection="row" alignItems="center" pt="10px">
        {children}
      </Box>
    </Container>
  </PageHeaderRender>
);

const mapState = (state) => ({
  user: state.auth.user
});

export default connect(mapState)(PageHeader);

const BreadCrumbWrapper = styled.div`
  li.blank {
    pointer-events: none !important;
    text-decoration: none !important;
  }
`

const PageHeaderRender = styled.div`
  box-shadow: inset 0px -1px 0px #e7e7f0;
  border: 1px solid #e7e7f0;
  padding: ${(p) => p.theme.spacing(2, 0)};
  ${space}
`;
