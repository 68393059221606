import styled from "styled-components";
import { toast, ToastContainer } from "react-toastify";

import ErrorIcon from "../assets/images/toast/clear_circle.svg";
import SuccessIcon from "../assets/images/toast/checkmark_circle.svg";
import WarningIcon from "../assets/images/toast/warning.svg";
import InfoIcon from "../assets/images/toast/info.svg";

const Toast = {
  error: (content, options = undefined) =>
    toast.error(content, { ...options, autoClose: false }),
  success: toast.success,
  warning: toast.warning,
  info: toast.info,
};

const StyledToastContainer = styled(ToastContainer).attrs({
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 6000,
  hideProgressBar: true,
  closeOnClick: false,
  draggable: false,
})`
  .Toastify__toast {
    color: #122640;
    border-top: 4px solid;
    border-radius: 4px;
    cursor: auto;
    font-family: ${(p) => p.theme.typography.fontFamily};
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;

    &::before {
      margin: ${(p) => p.theme.spacing("10px", 1, 0)};
    }

    & a {
      color: #122640;
      font-weight: 600;
      text-decoration: underline;
    }
  }

  .Toastify__toast--error {
    background-color: #fffcfd;
    border-color: #e5084a;

    &::before {
      content: url(${ErrorIcon});
    }
  }

  .Toastify__toast--success {
    background-color: #f8fffd;
    border-color: #0bb07b;

    &::before {
      content: url(${SuccessIcon});
    }
  }

  .Toastify__toast--warning {
    background-color: #fffdf9;
    border-color: #fcb704;

    &::before {
      content: url(${WarningIcon});
    }
  }

  .Toastify__toast--info {
    background-color: #fafdff;
    border-color: #0f9bf0;

    &::before {
      content: url(${InfoIcon});
    }
  }

  .Toastify__toast-body {
    margin: ${(p) => p.theme.spacing("10px", 0, 1)};
  }

  .Toastify__close-button {
    color: #0a1f44;
    margin: ${(p) => p.theme.spacing(1, 0.5, 0, 1)};
    opacity: 1;
  }
`;

export { Toast as toast, StyledToastContainer as ToastContainer };
