import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { Typography } from "cwb-react";

import { Box } from './Box';
import ErrorIcon from "../assets/images/icons/error.svg";

const TextArea = React.forwardRef(
  (
    {
      id,
      className,
      style,
      endAdornment,
      error = false,
      errorMessage,
      label,
      required,
      startAdornment,
      type = "text",
      ...rest
    },
    ref
  ) => {
    const rootProps = { id, className, style };
    const inputProps = { error, ref, required, type, ...rest };

    return (
      <Root {...rootProps}>
        <StyledLabel label={label}>
          {label}
          {label && required && (
            <StyledTypography color="error" component="span">
              *
            </StyledTypography>
          )}
          <AdornmentContainer
            endAdornment={endAdornment}
            startAdornment={startAdornment}
          >
            {startAdornment}
            <StyledInput {...inputProps} />
            {endAdornment}
          </AdornmentContainer>
        </StyledLabel>
        {error && errorMessage && (
          <StyledDiv>
            <StyledImg src={ErrorIcon} />
            <Typography color="error" variant="captionBold">
              {errorMessage}
            </Typography>
          </StyledDiv>
        )}
      </Root>
    );
  }
);

const Root = styled(Box)`
  max-width: 250px;
`;

const StyledLabel = styled.label`
  width: fit-content;
  display: initial;
  margin-bottom: initial;
  color: ${(p) => p.theme.palette.grey[7]};
  font-family: ${(p) => p.theme.typography.fontFamily};
  font-size: ${(p) => p.theme.input.label.fontSize};
  font-weight: ${(p) => p.theme.input.label.fontWeight};
  line-height: ${(p) => p.theme.input.label.lineHeight};

  ${(p) =>
    p.label &&
    css`
      & > div {
        margin-top: ${(p) => p.theme.spacing(1)};
      }
    `}
`;

const StyledTypography = styled(Typography)`
  font-size: ${(p) => p.theme.input.label.fontSize};
  font-weight: ${(p) => p.theme.input.label.fontWeight};
  line-height: ${(p) => p.theme.input.label.lineHeight};
`;

const AdornmentContainer = styled.div`
  width: 100%;
  position: relative;

`;

const StyledInput = styled.textarea`
  width: 100%;
  display: block;
  padding: ${(p) => p.theme.spacing(0.5, 1.5)};
  color: ${(p) => p.theme.palette.grey[7]};
  background-color: ${(p) => p.theme.palette.common.white};
  border: 1px solid ${(p) => p.theme.palette.common.grey};
  border-radius: 4px;
  box-sizing: border-box;
  font-size: ${(p) => p.theme.input.input.fontSize};
  font-weight: ${(p) => p.theme.input.input.fontWeight};
  line-height: ${(p) => p.theme.input.input.lineHeight};
  outline: none;

  &:focus {
    border-color: ${(p) => p.theme.palette.button.normal};
  }

  &::placeholder {
    color: ${(p) => p.theme.palette.grey[5]};
    opacity: 1;
  }

  &:read-only {
    color: ${(p) => p.theme.palette.grey[5]};
  }

  ${(p) =>
    p.error &&
    css`
      border-color: ${(p) => p.theme.palette.system.error};
    `}
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(0, "4px", "2px", 0)};
`;

TextArea.propTypes = {
  /**
   * When `true`, changes the border color to indicate that the input is in an error state.
   */
  error: PropTypes.bool,
  /**
   * Specifies the error message that is rendered below the input.
   */
  errorMessage: PropTypes.string,
  /**
   * Renders the given label above the input. Clicking the label focuses the input.
   */
  label: PropTypes.string,
  /**
   * Controls the input type. When `type="password"`, the input text is not be readable.
   */
  type: PropTypes.oneOf(["text", "password"]),
};

export default TextArea;
