import styled from "styled-components";
import { connect } from "react-redux";
import { Box } from "../../components";
import LoaderImg from "../../assets/images/icons/loader.png";
import SuccessIcon from "../../assets/images/icons/checkmark_circle.png";
import ErrorIcon from "../../assets/images/icons/error.svg";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Typography,
  ButtonGroup,
  Button,
} from "cwb-react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const UploadModal = ({ open, onClose, onGoToVideoPage, user, ...props }) => {

  const { loading, error } = props;
  const { t } = useTranslation();

  const [processedDone, setProcessedDone] = useState(false);
  useEffect(() => {
    if (open) {
      let timeout = setTimeout(() => {
        setProcessedDone(true)
        // 40 seconds
      }, 40 * 1000)
      return () => { timeout && clearTimeout(timeout) };
    }
  }, [open])

  return (
    <Modal unmountOnClose={true} backdrop={'static'} trapFocus={true} closeOnClick={false} isOpen={open}>
      <ColoredModalHeader error={!loading && processedDone && error} onClose={onClose}>
        <Box display="flex" justifyContent="center" width="100%">
          <Typography variant="h3" align="center" style={{ width: "100%" }}>
            {t("Your Pitch Reel is Encoding")}
          </Typography>
        </Box>
      </ColoredModalHeader>
      <ModalBody>
        {loading || !processedDone ? (
          <>
            <Box display="flex" justifyContent="center" mb="15px">
              <SpinningImg src={LoaderImg} />
            </Box>
            <Typography align="center">
              {user.id.userTypeId !== 8 ?
                t(
                  "Once completed, you can go to the actor's Edit Video page to set as Private, Viewable or Delete and start again."
                ) :
                t(
                  "Once completed, you can go to your Edit Video page to set as Private, Viewable or Delete and start again."
                )}
            </Typography>
          </>
        ) : (error ? (<ErrorRender>
          <img src={ErrorIcon} />
          <Box mt="20px">
            <Typography align="center">
              {t("Pitch Reel failed to encode. Please try again or contact our support.")}
            </Typography>
          </Box>
        </ErrorRender>) :
          (
            <SuccessRender>
              <img src={SuccessIcon} />
              <Box mt="20px">
                <Typography align="center">
                  {t("Pitch Reel encoded successfully!")}
                </Typography>
              </Box>
            </SuccessRender>
          )
        )}
      </ModalBody>
      <ModalFooter>
        <Box display="flex" justifyContent="center" style={{ width: "100%" }}>
          {(loading || !processedDone) ? (
            <ButtonGroup>
              <ButtonPadding onClick={onClose} variant="tertiary">
                {t("Close While Encoding")}
              </ButtonPadding>
              <ButtonPadding variant="primary" onClick={() => onGoToVideoPage()}>
                {t("Go to the Video Page Now")}
              </ButtonPadding>
            </ButtonGroup>
          ) : (error ? (
            <ButtonGroup>
              <ButtonPadding variant="tertiary" onClick={onClose} >
              {t("Close")}
              </ButtonPadding>
            </ButtonGroup>
          ) : (
            <ButtonGroup>
              <ButtonPadding variant="primary" onClick={() => onGoToVideoPage()}>
                {t("Go to the Video Page Now")}
              </ButtonPadding>
            </ButtonGroup>
          ))}
        </Box>
      </ModalFooter>
    </Modal>
  );
};

const ColoredModalHeader = styled(ModalHeader)`
  ${props => props.error && `border-top: 3px solid #E5084A !important;`};
`
const ButtonPadding = styled(Button)`
  padding-top:16px;
  padding-bottom:16px;
`
const SpinningImg = styled.img`
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  animation: rotate 2s linear infinite;
`
const ErrorRender = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 120px;
  background: #f8fffd;
  /* Accent/Error/500 */

  border: 1px solid #E5084A;
  border-radius: 4px;
`;


const SuccessRender = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 120px;
  background: #f8fffd;
  /* Accent/Green/500 */

  border: 1px solid #0bb07b;
  border-radius: 4px;
`;


const mapState = (state) => ({
  actor: state.actors.selected,
  user: state.auth.user
});

const mapDispatch = (dispatch) => ({
});

export default connect(mapState, mapDispatch)(UploadModal);
