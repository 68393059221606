import styled from "styled-components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";
import { Checkbox, TextInput, Typography, CWBTooltip, getCachedWebSessionKey } from "cwb-react";
import { Toggle } from "cwb-react";

import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";

import { EditTitleModal } from "./EditTitleModal";
import { EditTitleCardModal } from "./EditTitleCardModal";
import UploadModal from "./UploadModal";
import { createUploadAndProcessAPI } from "../../api/CWBAPI/pitchreel";

import { createPopUpOptions, getMediaPopUpUrl } from "../../helpers";

import {
  Button,
  Container,
  PageHeader,
  Box,
  Table,
  TableHead,
  TableCell,
  TableCellHead,
  Info,
} from "../../components";

import AffordanceIcon from "../../assets/images/icons/affordance.svg";
import EditIcon from "../../assets/images/icons/edit/white.svg";
import EditGreyIcon from "../../assets/images/icons/edit/grey.svg";
import MusicIcon from "../../assets/images/icons/music.svg";
import RemoveMusicIcon from "../../assets/images/icons/exclude-icon.png";

import { useNavigate } from "react-router-dom";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const UpdateVideos = ({ pitchReels, actor, setPitchReelTitle, fetchPitchReelCard, user }) => {

  const { t } = useTranslation();
  const { getRootProps, getInputProps, acceptedFiles, isFocused, isDragAccept, isDragReject } = useDropzone({ accept: { 'audio/*': ['.mp3'] } });

  const [count, forceUpdate] = useState(0);
  const navigate = useNavigate();

  const removeFile = (file) => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
    forceUpdate(count + 1);
  };

  const files = acceptedFiles.map((file) => (
    <StyledMusicItem key={file.path} onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      removeFile(file);
    }}>
      <img
        src={RemoveMusicIcon}
        alt=""
        width="20"
      />
      <Typography>{file.path}</Typography>
    </StyledMusicItem>
  ));

  const title = useMemo(
    () => actor
      ? `${t("Pitch Reel for")} ${actor.firstName} ${actor.lastName}`
      : "",
    [actor]
  );

  const [videos, setVideos] = useState(pitchReels && pitchReels.selected ? pitchReels.selected : []);

  const [selectedVideos, setSelectedVideos] = useState(Array.from(videos));

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showGoToVideoPage, setShowGoToVideoPage] = useState(false);

  useEffect(() => {
    if (pitchReels) {
      setVideos(pitchReels.selected);
    } else {
      setVideos([])
    }
  }, [pitchReels.selected]);

  useEffect(() => {
    if (pitchReels) {
      if (!pitchReels.selected || !pitchReels.selected.length) {
        navigate('/home');
      }
    }

  }, [pitchReels.selected])

  const [openEditModal, setOpenEditModal] = useState(false);

  const [enableFrontTitle, setEnableFrontTitle] = useState(false);
  const [frontTitle, setFrontTitle] = useState("");

  const [showFrontTitleModal, setShowFrontTitleModal] = useState(false);

  const [enableBackTitle, setEnableBackTitle] = useState(false);
  const [backTitle, setBackTitle] = useState("");

  const [titleCardSetup, setTitleCardSetup] = useState(null);
  const [showBackTitleModal, setShowBackTitleModal] = useState(false);

  const isChecked = (video) =>
    !!selectedVideos.find((v) => v.mediaId === video.mediaId);

  // const onSelect = (video) => {
  //   let find = isChecked(video);

  //   setSelectedVideos((prev) => {
  //     if (!find) {
  //       return [...prev, video];
  //     }

  //     return prev.filter((v) => v.mediaId !== video.mediaId);
  //   });
  // };

  const getUploaderVideoPage = () => {
    if (!actor)
      return "#";

    const webSessionKey = getCachedWebSessionKey();
    const rosterTarget = actor.rosterId || actor.rosterRId;

    if (!rosterTarget || user.id.userTypeId == 8) {
      return process.env.REACT_APP_BASE_URL.toLowerCase().replace("www.", "httpuploadvan.") +
        `/upload_mastercast/mastercast_upload.aspx?c=${actor.clientId || actor.clientRId}&k=${webSessionKey}&a=0`
    }

    const result = `/castingworkbook/clients/vroster.asp?rt=${rosterTarget}&c=98`;
    return process.env.REACT_APP_BASE_URL.toLowerCase() + result;
  }

  const onGoToVideoPage = () => {
    window.location.href = getUploaderVideoPage();
  }

  const onSave = async () => {
    setShowUploadModal(true);
    setLoading(true);
    setShowGoToVideoPage(true);
    try {
      const formData = new FormData();
      formData.append(
        "pitchDto",
        JSON.stringify({
          clientRId: actor.clientId,
          rosterRId: actor.rosterId,
          frontCardEnabled: enableFrontTitle,
          frontCardText: frontTitle,
          backCardEnabled: enableBackTitle,
          backCardText: backTitle,
          pitchReelTitle: pitchReels.pitchReelTitle,
          hasMusic: acceptedFiles.length > 0,
          takes: videos.map((v, idx) => ({
            sortOrder: idx,
            videoId: v.mediaId,
            overlay: v.overlay,
            isEnabled: selectedVideos
              .map((sv) => sv.mediaId)
              .includes(v.mediaId),
            isStudio: v.isStudio || false,
            videoPublicUrl: v.sources?.length > 0 && v.sources[0].url,
          })),
        })
      );

      if (acceptedFiles.length > 0) {
        formData.append("audioFile", acceptedFiles[0])
      }
      await createUploadAndProcessAPI(actor.clientId, formData);
    } catch (e) {
      console.log("error:", e, videos);
      setError(true);
    }
    setLoading(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      videos,
      result.source.index,
      result.destination.index
    );

    setVideos(items);
  };

  const onEditTitle = () => {
    setOpenEditModal(true);
  };

  const onLinkClick = (ev, item) => {
    ev.preventDefault();
    let targetClipClientId = item.clientId || item.clientRId;
    if (!targetClipClientId)
      targetClipClientId = actor.clientId || actor.clientRId;

    const mediaUrl = getMediaPopUpUrl(targetClipClientId, item.mediaId, user.id.userTypeId)
    const popUpOptions = createPopUpOptions(window);
    window.open(mediaUrl, `${actor.firstName} ${actor.lastName} - ${t('Media')}`, popUpOptions);
  }

  const onRemoveClick = (ev, item) => {
    ev.preventDefault();
    const videoIndex = videos.indexOf(item);
    if (videoIndex > -1) {
      videos.splice(videoIndex, 1);
      setVideos(Array.from(videos));
    }

    const selectedIndex = selectedVideos.indexOf(item);
    if (selectedIndex > -1) {
      selectedVideos.splice(selectedIndex, 1);
      setSelectedVideos(Array.from(selectedVideos))
    }
  }

  const focusedStyle = {
    borderColor: '#2196f3'
  };

  const acceptStyle = {
    borderColor: '#00e676'
  };

  const rejectStyle = {
    borderColor: '#ff1744'
  };

  const style = useMemo(() => ({
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  let breadcrumbItems = null;
  if (user.id.userTypeId !== 8) {
    breadcrumbItems = [
      {
        to: "/home",
        title: t("Pitch Reels"),
      },
      {
        to: `/videos/${actor && actor.clientId}`,
        title: title,
      },
      {
        to: `/update-videos`,
        title: t("Save Pitch Reel"),
      },
    ]
  } else {
    breadcrumbItems = [
      {
        to: `/videos/${actor && actor.clientId}`,
        title: 'New Pitch Reel',
      },
      {
        to: `/update-videos`,
        title: t("Save Pitch Reel"),
      },
    ];
  }

  useEffect(() => {
    if (!actor)
      return;

    (async () => {
      const data = await fetchPitchReelCard();
      setTitleCardSetup(data);
    })();
  }, [actor])

  return (
    <>
      <PageHeader
        title={
          <PageTitleRender>
            {pitchReels.pitchReelTitle}{" "}
            <img src={EditGreyIcon} alt="" onClick={onEditTitle} />
          </PageTitleRender>
        }
        breadcrumbItems={breadcrumbItems}
      />
      <ContainerLimitedWidth pt="6px">
        <Table>
          <TableHead>
            <TableRow>
              <TableCellHead>{t("Order")}</TableCellHead>

              <TableCellHead>{t("Video")}</TableCellHead>
              <TableCellHead>{t("Clip Title")}</TableCellHead>
              <TableCellHead>{t("Length")}</TableCellHead>
              <TableCellHead>{t("Clip Caption")}<ClipCaptionInfo tooltip={t("What appears over the video")} /> </TableCellHead>

              <TableCellHead>
                {/* <Checkbox /> */}
              </TableCellHead>
            </TableRow>
          </TableHead>

          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="videoList">
              {(provided, snapshot) => {
                return (
                  <TableBody
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {videos.map((video, index) => {
                      return (
                        <Draggable
                          key={`video_${video.mediaId}`}
                          draggableId={`video_${video.mediaId}`}
                          index={index}
                        >
                          {(p, s) => {
                            return (
                              <TableRow
                                ref={p.innerRef}
                                {...p.draggableProps}
                                {...p.dragHandleProps}
                              >
                                <TableCell>
                                  <img src={AffordanceIcon} alt="drag" />
                                </TableCell>
                                <TableCell>
                                  <Render>
                                    {video.sources &&
                                      video.sources.length > 0 ? (
                                      <a href="#" onClick={(ev) => onLinkClick(ev, video)}><img
                                        src={video.polaroid}
                                        height={66}
                                        width={120}
                                        alt={video.description}
                                      /></a>
                                    ) : (
                                      <Typography>
                                        {t(
                                          "This actor does not have any media, please upload any videos before trying to create a Pitch Reel"
                                        )}
                                      </Typography>
                                    )}
                                  </Render>
                                </TableCell>
                                <TableCell>{video.description}</TableCell>
                                <TableCell>{(video.lengthFriendly || "").replace(/\.(\d+)/g, "")}</TableCell>
                                <TableCellClipCaption>
                                  <CustomTextInput
                                    value={video.overlay || ""}
                                    onChange={(event) => {
                                      setVideos((prev) => {
                                        return prev.map((v) => {
                                          if (v.mediaId === video.mediaId) {
                                            return {
                                              ...v,
                                              overlay: event.target.value,
                                            };
                                          }
                                          return v;
                                        });
                                      });
                                    }}
                                  />
                                </TableCellClipCaption>
                                <TableCellLast>
                                  <LinkExclude id={`link-exclude-${index}`} href="#" onClick={(ev) => onRemoveClick(ev, video)}>x</LinkExclude>
                                  <CWBTooltip target={`link-exclude-${index}`}>
                                    {t("Remove Clip")}
                                  </CWBTooltip>

                                </TableCellLast>
                              </TableRow>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </TableBody>
                );
              }}
            </Droppable>
          </DragDropContext>
        </Table>
        <Box
          display="grid"
          py="20px"
          gridTemplateColumns="minmax(auto, 0.7fr) 1fr 1fr"
          gridColumnGap="32px"
        >

          <Box
            border="1px solid #DEE3EB"
            borderRadius="4px"
            p="16px 20px"
            display={files.length === 0 ? "grid" : "flex"}
            gridTemplateColumns="140px auto"
            flexDirection="column"
            gridColumnGap="16px"
            {...getRootProps(style)}
          >
            <Box gridColumn="1 / 2 span">
              <Typography variant="bodyBold">{t("Add Music")} <MusicCaptionInfo tooltip={t("Upload an MP3 and only the first 5 seconds will play over your title card. Try one for fun.")} /> </Typography>
            </Box>
            {files.length === 0 ? (
              <>
                <StyledMusic isDragAccept={isDragAccept} isDragReject={isDragReject} isFocused={isFocused}>
                  <img src={MusicIcon} alt="" />
                </StyledMusic>
                <input {...getInputProps()} />
                <Box display="flex" alignItems="center">
                  <Hoverable>
                    <Typography>{t("Drag and Drop Here or Browse")}</Typography>
                  </Hoverable>
                </Box>
              </>
            ) : (
              <StyledMusicList>{files}</StyledMusicList>
            )}
          </Box>
          <Box
            border="1px solid #DEE3EB"
            borderRadius="4px"
            p="16px 20px"
            display="grid"
            gridTemplateColumns="1fr 2fr"
            gridColumnGap="16px"
          >
            <Box>
              <Typography variant="bodyBold">
                {t("Front Title Card")}
              </Typography>
              <Toggle
                checked={enableFrontTitle}
                onChange={(e) => {
                  if (!frontTitle) {
                    setFrontTitle(actor.firstName + ' ' + actor.lastName);
                  }
                  setEnableFrontTitle(e.target.checked)
                }
                }
              />
            </Box>
            <CardBoxWrapper>
              <Box
                minHeight="158px"
                background="black"
                opacity={enableFrontTitle ? 1 : 0.3}
                borderRadius="4px"
                alignItems="center"
                justifyContent="center"
                position="relative"
                maxWidth="280px"
                margin="0 auto;"
                display="flex"
              >
                {enableFrontTitle && (
                  <EditButton onClick={() => setShowFrontTitleModal(true)}>
                    <img src={EditIcon} alt={t("Edit")} />
                  </EditButton>
                )}
                <StyledTextCardContainer>
                  {/* <StyledTextArea value={frontTitle} readOnly /> */}
                  <StyledTextCard>{frontTitle}</StyledTextCard>
                </StyledTextCardContainer>
              </Box>
            </CardBoxWrapper>
          </Box>
          <Box
            border="1px solid #DEE3EB"
            borderRadius="4px"
            p="16px 20px"
            display="grid"
            gridTemplateColumns="1fr 2fr"
            gridColumnGap="16px"
          >
            <Box>
              <Typography variant="bodyBold">{t("End Title Card")}</Typography>
              <Toggle
                checked={enableBackTitle}
                onChange={(e) => {
                  setEnableBackTitle(e.target.checked);
                  if (!backTitle && titleCardSetup && titleCardSetup.backCardText) {
                    setBackTitle(titleCardSetup.backCardText);
                  }
                }
                }
              />
            </Box>
            <CardBoxWrapper>
              <Box
                minHeight="158px"
                background="black"
                opacity={enableBackTitle ? 1 : 0.3}
                borderRadius="4px"
                alignItems="center"
                justifyContent="center"
                position="relative"
                maxWidth="280px"
                margin="0 auto;"
                display="flex"
              >
                {enableBackTitle && (
                  <EditButton onClick={() => setShowBackTitleModal(true)}>
                    <img src={EditIcon} alt={t("Edit")} />
                  </EditButton>
                )}
                <StyledTextCardContainer>
                  {/* <StyledTextArea value={backTitle} readOnly /> */}
                  <StyledTextCard>{backTitle}</StyledTextCard>
                </StyledTextCardContainer>
              </Box>
            </CardBoxWrapper>
          </Box>
        </Box>
        <Box display="flex" justifyContent="right" py="20px">
          <LinkButton href={!showGoToVideoPage ? '#' : getUploaderVideoPage()}>
            <Button disabled={!showGoToVideoPage} type="button" variant="tertiary">{t("Go to the Video Page")}</Button>
          </LinkButton>
          <Button
            variant="primary"
            type="button"
            onClick={() => onSave()}
            disabled={selectedVideos.length === 0}
          >
            {t("Save")}
          </Button>
        </Box>
        <EditTitleModal
          open={openEditModal}
          onClose={() => setOpenEditModal(false)}
          title={pitchReels.pitchReelTitle}
          onSave={(newTitle) => {
            setPitchReelTitle(newTitle);
            setOpenEditModal(false);
          }}
        />
        <EditTitleCardModal
          open={showFrontTitleModal}
          onClose={() => setShowFrontTitleModal(false)}
          title={frontTitle}
          onSave={(newTitle) => {
            setFrontTitle(newTitle);
            setShowFrontTitleModal(false);
          }}
          modalTitle={t("Edit Front Title Card")}
          modalInstructions={t("Begin typing text you'd like to appear on the front title card.")}
        />

        <EditTitleCardModal
          open={showBackTitleModal}
          onClose={() => setShowBackTitleModal(false)}
          title={backTitle}
          onSave={(newTitle) => {
            setBackTitle(newTitle);
            setShowBackTitleModal(false);
          }}
          modalTitle={t("Edit End Title Card")}
          modalInstructions={t("Begin typing text you'd like to appear on the end title card.")}
        />
        <UploadModal
          loading={loading}
          error={error}
          open={showUploadModal}
          onClose={() => {
            setShowUploadModal(false);
            setError(false);
          }}
          onGoToVideoPage={() => {
            setShowUploadModal(false);
            setError(false);
            onGoToVideoPage();
          }}
        />
      </ContainerLimitedWidth>
    </>
  );
};

const mapState = (state) => ({
  pitchReels: state.videos.current,
  actor: state.actors.selected,
  user: state.auth.user
});

const mapDispatch = (dispatch) => ({
  setPitchReelTitle: dispatch.videos.setPitchReelTitle,
  fetchPitchReelCard: dispatch.videos.fetchPitchReelCard
});

export default connect(mapState, mapDispatch)(UpdateVideos);

const CustomTextInput = styled(TextInput)`
  max-width: 290px;
`
const TableCellClipCaption = styled(TableCellHead)`
  padding: 0 0 0 8px;
`
const TableCellLast = styled(TableCellHead)`
  padding: 0 16px 0 0;
`
const Hoverable = styled.div`
  cursor: pointer;
`

const LinkExclude = styled.a`
padding: 0px;
margin: 0px 0px 0px 5px;
display: inline-block;
height: 20px;
vertical-align: top;
font-size: 20px;
letter-spacing: normal;
line-height: 20px;
transform: scaleY(0.8);
text-decoration: none;
&:hover {
  color:  #0056b3;
  text-decoration: none;
}
}`
const ClipCaptionInfo = styled(Info)`
margin-left:5px;
position:relative;
top: 2px;
`
const MusicCaptionInfo = styled(Info)`
margin-left:5px;
top: 2px;
z-index: 99;
`
const ContainerLimitedWidth = styled(Container)`
  max-width: 1600px;
  padding-top: 24px;
`

const TableBody = styled.tbody`
  & > tr:nth-of-type(even) {
    background-color: #f8f9fb;
  }
`;

const TableRow = styled.tr`
  height: 40px;
`;

const PageTitleRender = styled.span`
  display: flex;
  align-items: center;
  img {
    margin-left: 10px;
    cursor: pointer;
  }
`;

const Render = styled.div`
  margin: 5px;
  position: relative;
  display: inline-flex;

  align-items: center;
  justify-content: center;

  width: 120px;
  min-height: 67px;

  img {
    vertical-align: middle;
    object-fit: cover;
  }
`;


const LinkButton = styled.a`
  margin-right:10px;
  &:hover {
    text-decoration: none;
  }
`

const EditButton = styled.div`
  background: #0f9bf0;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  cursor: pointer;

  position: absolute;

  top: 10px;
  right: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

const CardBoxWrapper = styled.div`
  text-align:center;
`

const StyledTextCardContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
`

const StyledTextCard = styled.span`
  font-size: 10px;
  line-height: 10.2px;
  color: white;
  white-space: pre;
`

const StyledTextAreaContainer = styled.div`
display: grid;
&::after {
  /* Note the weird space! Needed to preventy jumpy behavior */
  content: attr(data-replicated-value) " ";

  /* This is how textarea text behaves */
  white-space: pre-wrap;

  /* Hidden from view, clicks, and screen readers */
  visibility: hidden;
}
& > textarea {
  /* You could leave this, but after a user resizes, then it ruins the auto sizing */
  resize: none;

  /* Firefox shows scrollbar on growth, you can hide like this. */
  overflow: hidden;
}
& > textarea,
& ::after {
  /* Identical styling required!! */
  border: 1px solid black;
  padding: 0.5rem;
  font: inherit;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
}
`
const StyledTextArea = styled.textarea`
  background: #000000;
  border-radius: 4px;

  width: auto;
  height: 100%;

  color: #fff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  letter-spacing: 0.33px;

  padding: 10px;
  text-align: left;
  caret-color: transparent;
  border: 0;
  &:focus-visible {
    outline: 0;
  }
`;

const StyledMusic = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 140px;
  background: rgba(222, 227, 235, 0.3);
  border: 1px dashed #cfd9e9;
  border-radius: 4px;
  ${props => props.isFocused ? "border-color: #2196f3 !important;" : ""}
  ${props => props.isDragReject ? "border-color: #ff1744 !important;" : ""}
  ${props => props.isDragAccept ? "border-color: #00e676 !important;" : ""}
  cursor: pointer;
`;

const StyledMusicItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;

  img {
    margin-right: 10px;
  }
  cursor: pointer;
`;

const StyledMusicList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  background: rgba(222, 227, 235, 0.3);
  border: 1px dashed #cfd9e9;
  border-radius: 4px;

  padding: 15px 20px;

  position: relative;
  z-index: 2;

`;
