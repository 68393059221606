import { Outlet, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "cwb-react";
import { jwtAuthAPI } from "../api/CWBAPI/auth";
import { Loading } from "../components";
import { setUserLang } from '../helpers';

import "react-toastify/dist/ReactToastify.css";

function CastlistsLayout({
  isAuthenticated,
  loading,
  user,
  notification,
  setAuthenticated,
  getCurrentUser,
}) {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (notification && notification.message !== "") {
      switch (notification.type) {
        case "success":
          toast.success(notification.message);
          break;
        case "error":
          toast.error(notification.message);
          break;
        case "warning":
          toast.warning(notification.message);
          break;
        case "info":
          toast.warning(notification.message);
          break;
      }
    }
  }, [notification, toast]);

  useEffect(() => {
    const getToken = async (cookie) => {
      try {
        const { data: tokenData } = await jwtAuthAPI(cookie);
        setAuthenticated(tokenData);
        if (searchParams.get('k')) {
          searchParams.delete('k')
          setSearchParams(
            searchParams.toString()
          )
        }
      } catch (e) {
        return (window.location.href = `${process.env.REACT_APP_LOGIN_URL}${window.location.href}`);
      }
    };

    if (!isAuthenticated && !loading) {
      let cookie = searchParams.get(
        process.env.REACT_APP_QUERYSTR_COOKIE_KEY || "k"
      );
      getToken(cookie);

      let accessToken = searchParams.get(
        process.env.REACT_APP_QUERYSTR_TOKEN_KEY || "token"
      );
      if (accessToken) {
        setAuthenticated({
          accessToken,
        });
      }
    }
  }, [searchParams, setAuthenticated, isAuthenticated, loading]);

  useEffect(() => { });

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    (async () => {
      const data = await getCurrentUser();
      setUserLang(data.languageChoice);
    })()
  }, [isAuthenticated, getCurrentUser]);

  if (!user) {
    return <Loading show={true} />;
  }
  return (
    <>
      <Outlet />
      <ToastContainer />
    </>
  );
}

const mapState = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.common.loading > 0,
  isAuthenticating: state.auth.isAuthenticating,
  user: state.auth.user,
  notification: state.common.notification,
});

const mapDispatch = (dispatch) => ({
  setAuthenticated: dispatch.auth.setAuthenticated,
  getCurrentUser: dispatch.auth.getCurrentUser,
});

export default connect(mapState, mapDispatch)(CastlistsLayout);
