import styled from 'styled-components';
import { Box } from "../components";

export const Image = ({ width, height, src, alt }) => {
  return (
    <Box pt={`${(height / width) * 100}%`} position="relative" width="100%">
      <CustomImg src={src} alt={alt} />
    </Box>
  );
};

const CustomImg = styled.img`
  position: absolute;

  top: 50%;
  left: 50%;

  transform: translateY(-50%) translateX(-50%);
`;
