import { CWBClient } from "../../client";
import ActorMockData from "../../mock/castlist.json";

export const fetchMediaAPIWithRoster = (clientId, rosterId, params) =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: ActorMockData }))
    : CWBClient.get(`${process.env.REACT_APP_API_PM_BASE_PATH || ''}/Media/${clientId}/roster/${rosterId}`, { params });

export const fetchMediaAPI = (clientId, params) =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: ActorMockData }))
    : CWBClient.get(`${process.env.REACT_APP_API_PM_BASE_PATH || ''}/Media/${clientId}`, { params });

export const fetchStudioMediaAPIWithRoster = (clientId, rosterId, params) =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: ActorMockData }))
    : CWBClient.get(`${process.env.REACT_APP_API_PM_BASE_PATH || ''}/Media/${clientId}/roster/${rosterId}/studio`, { params });

export const fetchStudioMediaAPI = (clientId, params) =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: ActorMockData }))
    : CWBClient.get(`${process.env.REACT_APP_API_PM_BASE_PATH || ''}/Media/${clientId}/studio`, { params });

export const fetchPitchReelCardWithRoster = (clientId, rosterId, params) =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: ActorMockData }))
    : CWBClient.get(`${process.env.REACT_APP_API_PM_BASE_PATH || ''}/PitchReel/${clientId}/roster/${rosterId}/card`, { params });

export const fetchPitchReelCard = (clientId, params) =>
  process.env.REACT_APP_MOCK_ON === "TRUE"
    ? new Promise((resolve) => resolve({ data: ActorMockData }))
    : CWBClient.get(`${process.env.REACT_APP_API_PM_BASE_PATH || ''}/PitchReel/${clientId}/card`, { params });


