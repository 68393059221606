import styled from "styled-components";
import {
  border,
  space,
  color,
  flexbox,
  layout,
  position,
  shadow,
  typography,
  boxShadow,
  grid,
  background
} from "styled-system";

export const Box = styled.div(
  {},
  space,
  color,
  background,
  border,
  flexbox,
  layout,
  position,
  shadow,
  typography,
  boxShadow,
  grid
);
