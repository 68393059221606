import * as api from "../api/CWBAPI/actor";

export const actors = {
  state: {
    list: [],
    selected: null,
  },
  reducers: {
    setList(state, list) {
      return {
        ...state,
        list,
      };
    },
    setSelected(state, selected) {
      return {
        ...state,
        selected,
      };
    },
  },
  effects: (dispatch) => ({
    async fetchActors(_, state) {
      dispatch.common.setLoading(true);

      try {
        const { data: actors } = await api.fetchActorsAPI(
          state.auth.user?.id?.userId
        );

        const sorted = actors.sort((a,b) =>  {
          var aTemp = ((a.lastName || "") + " " + (a.firstName || "")).trim();
          var bTemp = ((b.lastName || "") + " " + (b.firstName || "")).trim();

          return aTemp.localeCompare(bTemp);
        });

        dispatch.actors.setList(sorted);
        dispatch.common.setAPISuccess();

        return actors;
      } catch (e) {
        dispatch.common.setAPIError(e);
        dispatch.common.errorRedirect(e);
      }
    },

    async fetchActor(actorId) {
      dispatch.common.setLoading(true);

      try {
        const { data: actor } = await api.fetchActorDetailAPI(actorId);

        dispatch.actors.setSelected(actor);
        dispatch.common.setAPISuccess();

        return actor;
      } catch (e) {
        dispatch.common.setAPIError(e);
        dispatch.common.errorRedirect(e);        
      }
    },
  }),
};
