import React, { Fragment } from 'react'
import styled from 'styled-components'
import { CWBTooltip } from 'cwb-react'

const Info = ({ className, innerClassName, message, tooltip }) => {
  const number = Math.floor(Math.random() * 999999999);
  return (<Fragment>
      <InfoIcon id={`info-icon-${number}`} className={`icon-cwb-information ${className}`}  />
      {message && (<Wrapper>{message}</Wrapper>)}
      {tooltip && (<CWBTooltip innerClassName={innerClassName} target={`info-icon-${number}`}>
        {tooltip}
      </CWBTooltip>)
      }
    </Fragment>)
}

const Wrapper = styled.span`
  font-size: 12px;
  font-weight: normal;
  color: #353B48 !important;
  padding-left: 6px;
  vertical-align: middle;
`

const InfoIcon = styled.span`
  &:hover  {
    cursor: pointer;
  }
  &:before {
    color: #B5D4F1;
  }
`

export default Info