import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { setLanguage, detectBrowserLang } from "./helpers";

import translationEN from './i18n/en/resource.json';
import translationES from './i18n/es/resource.json';
import translationFR from './i18n/fr/resource.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: {...translationEN, ...translationES }
  },
  fr: {
    translation: {...translationEN, ...translationFR }
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  nsSeparator: false,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
