import { getAuthToken, setUserAuthToken } from 'cwb-react';

export function getTokenFromStorage() {
  try {
    return getAuthToken()
  } catch (e) {
    return null;
  }
}

export function setTokenToStorage(tokenData) {
  setUserAuthToken(tokenData.accessToken)
}


export function getTotalSeconds(date) {
  let d = date;
  if (!(date instanceof Date)) {
    d = new Date(date);
  }
  return Math.floor(d.getTime() / 1000);
}
