import styled, { css } from "styled-components";

import { Button } from "cwb-react";

const StyledButton = styled(Button)`
  ${(p) =>
    p.variant === "transparent" &&
    css`
      color: ${(p) => p.theme.typography.color.darkGrey};
      background-color: ${(p) => p.theme.palette.common.transparent};
      border: 1px solid ${(p) => p.theme.palette.common.transparent};

      &:hover {
        background-color: ${(p) => p.theme.palette.common.transparent};
        border: 1px solid ${(p) => p.theme.palette.button.hover};
      }

      &:active {
        background-color: ${(p) => p.theme.palette.common.transparent};
        border: 1px solid ${(p) => p.theme.palette.button.pressed};
      }

      ${(p) =>
        p.destructive &&
        css`
          background-color: ${(p) => p.theme.palette.system.error};
          border: 1px solid ${(p) => p.theme.palette.system.error};

          &:hover {
            background-color: ${(p) => p.theme.palette.system.errorDark};
            border: 1px solid ${(p) => p.theme.palette.system.errorDark};
          }

          &:active {
            background-color: ${(p) => p.theme.palette.system.errorDark};
            border: 1px solid ${(p) => p.theme.palette.system.errorDark};
          }
        `}

      &:disabled {
        color: ${(p) => p.theme.palette.common.white};
        background-color: ${(p) => p.theme.typography.color.disabled};
        border: 1px solid ${(p) => p.theme.typography.color.disabled};
        cursor: not-allowed;
      }
    `}
`;

const ExtendedButton = ({ variant, ...extraProps }) => {
  if (variant === "transparent") {
    return <StyledButton variant={variant} {...extraProps} />;
  }
  return <Button {...extraProps} variant={variant} />;
};

export default ExtendedButton