import { moment } from 'cwb-react';
import i18n from "./../i18n";

const DEFAULT_OPTIONS = {
    toolbar: 'no',
    location: 'no',
    directories: 'no',
    status: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'yes',
    width: 920,
    height: 645,
    top: (o, w) => (w.innerHeight - (o.height * 1.2)) / 2 + (w.screenY),
    left: (o, w) => (w.innerWidth - o.width) / 2 + w.screenX
};

export const createPopUpOptions = (ownerWindow, customOptions) => {
    const mergedOptions = { ...DEFAULT_OPTIONS, ...customOptions };

    return Object.keys(mergedOptions)
        .map(
            key =>
                key +
                '=' +
                (typeof mergedOptions[key] === 'function'
                    ? (mergedOptions[key].call(this, mergedOptions, ownerWindow))
                    : mergedOptions[key])
        )
        .join(',');
}


export const getMediaPopUpUrl = (clientId, mediaId, userTypeId) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    if (userTypeId == 8) {
        const agentPath = `/actorsworkbook/clients/videoclip.asp?id=${clientId}&vid=${mediaId}`;
        return baseUrl + agentPath;
    }
    const agentPath = `/castingworkbook/clients/videoclip.asp?vtype=1&id=${clientId}&vid=${mediaId}`;
    return baseUrl + agentPath;
}

export const languageCode = { 0: 'en', 1: 'fr', 2: 'en_uk', 3: 'es', 99: 'nb' }
export const localeKeys = { en: 0, fr: 1, 'en_uk': 2, es: 3, nb: 99 }
export const detectBrowserLang = (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

export const isNum = (val) => {
    return !isNaN(val)
}
export const setUserLang = (langChoice) => {
    if (!langChoice) {
        langChoice = detectBrowserLang.toLowerCase();
    }

    let tempLangCode = 'en';
    let browserLang;
    if (typeof langChoice === 'number') {
        tempLangCode = languageCode[langChoice] || 'en'
        browserLang = tempLangCode;
    } else if(isNum(langChoice)) {
        tempLangCode = languageCode[Number.parseInt(langChoice)] || 'en'
        browserLang = tempLangCode;
    } else {
        browserLang = langChoice;
    }

    if (langChoice === 'en-gb') {
        i18n.changeLanguage('en_uk');
        moment.moment.locale('en-gb');
    } else if (localeKeys[browserLang.split("-")[0]]) {
        const targetLocale = browserLang.split("-")[0];
        setLanguage(targetLocale);
    } else {
        setLanguage('en');
    }
}

export const setLanguage = (langCode) => {
    i18n.changeLanguage(langCode);
    moment.moment.locale(langCode);
};