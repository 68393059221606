import React from 'react';
import styled from 'styled-components';

const Table = ({ children, id, className, style }) => {
  const props = { id, className, style };

  return <StyledTable {...props}>{children}</StyledTable>;
};

const TableHead = ({ children, id, className, style }) => {
  const props = { id, className, style };

  return <StyledThead {...props}>{children}</StyledThead>;
};

const TableBody = ({ children, id, className, style }) => {
  const props = { id, className, style };

  return <StyledTbody {...props}>{children}</StyledTbody>;
};

const TableRow = ({ children, id, className, style }) => {
  const props = { id, className, style };

  return <StyledTr {...props}>{children}</StyledTr>;
};

const TableCellHead = ({ children, id, className, style }) => {
  const props = { id, className, style };

  return <StyledTh {...props}>{children}</StyledTh>;
};

const TableCell = ({
  children,
  id,
  className,
  style,
  colSpan = 1
}) => {
  const props = { id, className, style, colSpan };

  return <StyledTd {...props}>{children}</StyledTd>;
};

const StyledTable = styled.table`
  width: 100%;
  background-color: ${(p) => p.theme.palette.common.white};
  border: 1px solid #E7E7F0;
  border-collapse: unset;
  border-radius: 4px;
  border-spacing: 0;
  box-shadow: 0 2px 4px #e0e0e0;
`;

const StyledThead = styled.thead`
  height: 51px;
  background-color: #f8f9fb;
  box-shadow: inset 0 -1px 0 #E7E7F0;
  text-align: left;
`;

const StyledTbody = styled.tbody`
  & > tr:nth-of-type(even) {
    background-color: #f8f9fb;
  }
`;

const StyledTr = styled.tr`
  height: 40px;
`;

const StyledTh = styled.th`
  color: #353b48;
  padding: ${(p) => p.theme.spacing(0, 2)};
  font-size: 13px;
  font-weight: 600;
  line-height: 19px;
`;

const StyledTd = styled.td`
  color: ${(p) => p.theme.typography.color.darkGrey};
  padding: ${(p) => p.theme.spacing(0, 2)};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

export { Table, TableHead, TableBody, TableRow, TableCellHead, TableCell };
