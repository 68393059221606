import ReactSelect from 'react-select';
import styled from 'styled-components';

export const Select = styled(ReactSelect)`
  .react-select__value-container {
    font-size: ${p => p.theme.typography.fontSize.body};
    font-weight: ${p => p.theme.typography.fontWeight.body};
  }

  .react-select__control {
    border: solid 1px ${p => p.error ? p.theme.palette.system.error : p.theme.palette.common.grey};
    min-height: 35px;

    &:hover {
      border: solid 1px ${p => p.theme.palette.button.normal};
      box-shadow: none;
    }
  }

  .react-select__indicator {
    height: 33px;
    widht: 33px;
  }
`;

Select.defaultProps = {
  classNamePrefix: 'react-select'
}