import axios from "axios";
import { getTokenFromStorage } from "../utils";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

instance.interceptors.request.use((config) => {
  let tokenData = getTokenFromStorage();

  if (tokenData) {
    config.headers.Authorization = `Bearer ${tokenData}`;
  }
  return config;
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (
      error.response &&
      error.response.status >= 500
    ) {
      window.location.href = process.env.REACT_APP_500
    }
    return Promise.reject(error);
  }
);

export default instance;
