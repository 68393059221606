import { useState } from "react";
import { Box } from "../../components";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Typography,
  ButtonGroup,
  Button,
  TextInput,
} from "cwb-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const EditTitleModal = ({ open, onClose, onSave, ...props }) => {
  const [title, setTitle] = useState(props.title);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  const onChange = (event) => {
    setTitle(event.target.value);
  };

  const { t } = useTranslation();
  return (
    <Modal isOpen={open} onClose={onClose}>
      <ModalHeader onClose={onClose}>
        <Box display="flex" justifyContent="center" width="100%">
          <Typography variant="h3" align="center" style={{ width: "100%" }}>
            {t("Edit Pitch Reel")}
          </Typography>
        </Box>
      </ModalHeader>
      <ModalBody>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb="20px">
            <Typography align="center">
              {t("Enter the Pitch Reel clip title name below")}
            </Typography>
          </Box>

          <TextInput value={title} onChange={onChange} />
        </Box>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" justifyContent="center" style={{ width: "100%" }}>
          <ButtonGroup>
            <Button onClick={onClose} variant="tertiary">
              {t("Cancel")}
            </Button>
            <Button
              variant="primary"
              onClick={() => onSave(title)}
              disabled={title === ""}
            >
              {t("Save")}
            </Button>
          </ButtonGroup>
        </Box>
      </ModalFooter>
    </Modal>
  );
};
