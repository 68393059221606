import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Typography, TextInput, ButtonGroup, Button } from "cwb-react";

import { useNavigate } from "react-router-dom";
import { Redirect, } from 'react-router'
import { connect } from "react-redux";

import styled from "styled-components";

import { Container, PageHeader, Box, Loading, Info } from "../../components";
import { Backgrop } from "../../components/Backdrop";
import SearchIcon from "../../assets/images/icons/search-white-128.png";
import { Roster } from "./Roster";

import { AutoSizer, List } from 'react-virtualized';

const SelectActor = ({
  actors,
  user,
  fetchActors,
  selectActor,
  loading,
  ...props
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchStr, setSearchStr] = useState("");

  const [selectedRoster, setSelectedRoster] = useState(props.selectedRoster);

  useEffect(() => {
    if (!user || !user.id)
      return;

    if (user.id.userTypeId === 8) {
      navigate(`/videos/${user.id.userId}`);
      return;
    }

    fetchActors();
  }, [user]);

  useEffect(() => {
    setSelectedRoster(props.selectedRoster);
  }, [actors, props.selectedRoster]);

  const filteredActors = useMemo(() => {
    return actors.filter((actor) =>
      `${actor.firstName} ${actor.lastName}`
        .toLowerCase()
        .includes(searchStr.toLocaleLowerCase())
    );
  }, [actors, searchStr]);

  const rowRenderer = ({
    key, // Unique key within array of rows
    index, // Index of row within collection
    isScrolling, // The List is currently being scrolled
    isVisible, // This row is visible within the List (eg it is not an overscanned row)
    style, // Style object to be applied to row (to position it)
  }) => {
    return (
      <Box style={{...style}} key={key}>
        <Roster
          style={{paddingBottom:"10px"}}
          roster={filteredActors[index]}
          onSelect={() => setSelectedRoster(filteredActors[index])}
          selected={selectedRoster?.rosterId === filteredActors[index].rosterId}
        />
      </Box>
    );
  }

  return (
    <>
      <PageHeader
        title={user && user.sellerId == 100 ? `${t("Agency")}: ${user.company}` : ""}
        breadcrumbItems={[
          {
            to: "/home",
            title: t("Pitch Reels"),
          },
        ]}
      />
      <Backgrop />
      <Container
        display="flex"
        alignItems="center"
        justifyContent="center"
        pt="64px"
      >
        <Box
          width="560px"
          borderTop={`solid 6px #00AAFF`}
          borderRadius="4px"
          p="0 41px 24px"
          boxShadow="0px 2px 4px rgba(0, 0, 0, 0.2)"
          position="relative"
          background="white"
        >
          <Box py="22px" mb="2px">
            <Typography variant="h2" align="center">
              {t("Create a new Pitch Reel")}  <TitleInfo innerClassName={'wide-tooltip'} tooltip={t(`Pitch Reels allows you to select one of your actors and create a new demo reel in a couple of minutes using their uploaded clips. Try one for fun.`)} />
            </Typography>
          </Box>
          <Box mb={18}>
            <Typography align="center">
              {t(
                "Type an actor’s name and select them to begin"
              )}
            </Typography>
          </Box>
          <Box display="flex" mb="10px">
            <TextInput
              placeholder={t("Search Actor")}
              style={{ maxWidth: "none", width: "100%" }}
              value={searchStr}
              onChange={(e) => setSearchStr(e.target.value)}
            />
            <Box ml={10}>
              <Button type="button" variant="primary">
                <img
                  src={SearchIcon}
                  alt={t("Search Actor")}
                  width="16"
                  height="16"
                />
              </Button>
            </Box>
          </Box>

          <RenderRosterList maxHeight="350px" overflow="none">
            {loading ? (
              <Loading varant="embed" show={loading} />
            ) : (
              <AutoSizer disableHeight disableWidth>
                {({ height, width }) => (
                  <List width={1} overscanRowsCount={10} rowCount={filteredActors.length} rowHeight={100} height={350}
                    rowRenderer={rowRenderer}
                    containerStyle={{
                      width: "100%",
                      maxWidth: "100%"
                    }}
                    style={{
                      width: "100%"
                    }}
                  />
                )}
              </AutoSizer>
            )}
          </RenderRosterList>

          <Box mt="24px" display="flex" justifyContent="center">
            <ButtonGroup>
              <Button
                type="button"
                variant="secondary"
                onClick={() => navigate(-1)}
              >
                {t("Cancel")}
              </Button>
              <Button
                type="button"
                variant="primary"
                disabled={!selectedRoster}
                onClick={() => {
                  selectActor(selectedRoster);
                  navigate(`/videos/${selectedRoster.clientId}`);
                }}
              >
                {t("Select")}
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Container>
    </>
  );
};

const mapState = (state) => ({
  actors: state.actors.list,
  user: state.auth.user,
  loading: state.common.loading > 0,
  selectedRoster: state.actors.selected,
});

const mapDispatch = (dispatch) => ({
  selectActor: dispatch.actors.setSelected,
  fetchActors: dispatch.actors.fetchActors,
});

export default connect(mapState, mapDispatch)(SelectActor);

const TitleInfo = styled(Info)`
  margin-left:2px;
  font-size: 16px;
  &:hover  {
    cursor: pointer;
  }
`
const RenderRosterList = styled(Box)`
  & > * {
    margin-bottom: 10px;
  }

  // padding-right: 10px;
  .ReactVirtualized__List {
    padding-right: 10px;
  }
`;
