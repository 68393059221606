
import { getCurrentUserAPI } from "../api/CWBAPI/user";
import { setTokenToStorage } from "../utils";

export const auth = {
  state: {
    isAuthenticated: false,
    user: null
  },
  reducers: {
    setAuthenticated(state, payload = undefined) {
      if (payload) {
        setTokenToStorage(payload);
        return {
          ...state,
          isAuthenticated: true,
        };
      }
      return {
        ...state,
        isAuthenticated: false,
      };
    },
    setUser(state, user) {
      return {
        ...state,
        user,
      }
    }
  },
  effects: (dispatch) => ({
    async getCurrentUser() {
      dispatch.common.setLoading(true);
      try {
        const { data: user } = await getCurrentUserAPI();
        dispatch.auth.setUser(user);
        dispatch.common.setAPISuccess();
        return user;
      } catch (e) {
        dispatch.common.setAPIError(e);
      }
    }
  }),
};
