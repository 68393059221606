import { useState } from "react";

import styled from "styled-components";
import { Box } from "../../components";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Typography,
  ButtonGroup,
  Button,
} from "cwb-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import TextareaAutosize from 'react-textarea-autosize';

export const EditTitleCardModal = ({ open, onClose, onSave, ...props }) => {
  const [title, setTitle] = useState(props.title);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  const onChange = (event) => {
    setTitle(event.target.value);
  };

  const onCancelClose = (event) => {
    setTitle(props.title);
    onClose(event)
  }

  const { t } = useTranslation();
  return (
    <Modal isOpen={open} onClose={onCancelClose}>
      <ModalHeader onClose={onClose}>
        <Box display="flex" justifyContent="center" width="100%">
          <Typography variant="h3" align="center" style={{ width: "100%" }}>
            {t(props.modalTitle) || props.modalTitle}
          </Typography>
        </Box>
      </ModalHeader>
      <ModalBody>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box mb="20px">
            <Typography align="center">
              {t(props.modalInstructions) || props.modalInstructions}
            </Typography>
          </Box>
          <StyledTextAreaContainer onClick={(ev) => { document.getElementById("styledTextAreaCard").focus() }}>
            <StyledTextArea id="styledTextAreaCard" maxRows={10} value={title} onChange={onChange} />
          </StyledTextAreaContainer>
        </Box>
      </ModalBody>
      <ModalFooter>
        <Box display="flex" justifyContent="center" style={{ width: "100%" }}>
          <ButtonGroup>
            <Button onClick={onCancelClose} variant="tertiary">
              {t("Cancel")}
            </Button>
            <Button variant="primary" onClick={() => onSave(title)}>
              {t("Save")}
            </Button>
          </ButtonGroup>
        </Box>
      </ModalFooter>
    </Modal>
  );
};

const StyledTextAreaContainer = styled.div`
    height: 198px;
    background: black;
    width: 352px;
    display: flex;
    flex-direction: row;
    align-items: center;
`

const StyledTextArea = styled(TextareaAutosize)`
  background: #000000;
  border-radius: 4px;

  width: 100%;
  max-height: 198px;

  color: #fff;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  overflow: hidden;
  letter-spacing: 0.2px;


  padding: 0;
  margin:0;
  text-align: center;

  caret-color: white;

  border: 0;
  &:focus-visible {
    outline: 0;
  }

  resize:none;
`;
